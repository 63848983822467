<template>
  <div>
    Template Footer
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import imageUrlMixin from '@/utils/imageUrlMixin';
import mixinTheme from '@/components/mixins/mixinTheme';

export default {
  name: 'TemplateFooter',
  mixins: [imageUrlMixin, mixinTheme],
  computed: {
    ...mapGetters({
      marketerId: 'GET_MARKETER_ID',
      whiteLabel: 'GET_WHITE_LABEL_DATA',
      marketerAgencyContent: 'GET_MARKETER_AGENCY_CONTENT',
      device: 'GET_DEVICE',
      lang: 'GET_LANGUAGE',
      footerPagesList: 'GET_FOOTER_PAGES_LIST',
      footerCategoryList: 'GET_FOOTER_CATEGORY_LIST',
    }),
    pageList() {
      const { footerPagesList, footerCategoryList, firstShowingCount } = this;
      if (!footerPagesList || !footerCategoryList) return null;
      const list = [];
      if (this.showMainFooter) {
        // sort the category list
        footerCategoryList.sort((a, b) => (a.sort < b.sort ? -1 : 1));

        const pageItems = Object.entries(footerPagesList).filter((item) => (item[1].active && item[0] !== 'AgreementApp'));
        footerCategoryList.forEach((cat) => {
          if (cat.code !== 'CD') {
            const subList = [];
            subList[0] = cat.code;
            const itemList = pageItems.filter((page) => (page[1].category === cat.code));
            itemList.sort((a, b) => (a[1].sort > b[1].sort ? -1 : 1));
            subList[1] = itemList.slice(0, firstShowingCount);
            subList[2] = itemList.slice(firstShowingCount, itemList.length);
            subList[3] = false;
            list.push(subList);
          }
        });
      }

      const pageItems = Object.entries(footerPagesList).filter((item) => (item[1].active && item[0] !== 'AgreementApp'));
      footerCategoryList.forEach((cat) => {
        if (cat.code === 'CD') {
          const subList = [];
          subList[0] = cat.code;
          const itemList = pageItems.filter((page) => (page[1].category === cat.code));
          itemList.sort((a, b) => (a[1].sort > b[1].sort ? -1 : 1));
          subList[1] = itemList.slice(0, firstShowingCount);
          subList[2] = itemList.slice(firstShowingCount, itemList.length);
          subList[3] = false;
          list.push(subList);
        }
      });
      return list;
    },
    pageListFull() {
      const { footerPagesList, footerCategoryList } = this;
      if (!footerPagesList || !footerCategoryList) return null;

      const list = [];
      const pageItems = Object.entries(footerPagesList).filter((item) => (item[1].active && item[0] !== 'AgreementApp'));
      footerCategoryList.forEach((cat) => {
        const subList = [];
        subList[0] = cat.code;
        subList[1] = this.categoryName(cat.code);

        const itemList = pageItems.filter((page) => (page[1].category === cat.code));
        itemList.sort((a, b) => (a[1].sort > b[1].sort ? -1 : 1));
        // console.log(itemList);
        subList[2] = itemList.map((el) => [
          el[1].link,
          el[1].title,
        ]);
        list.push(subList);
      });
      return list;
    },
    pageCount() {
      const { pageList } = this;
      if (!pageList) return 0;

      return pageList.length;
    },
    pageCount1() {
      const { pageList1 } = this;
      if (!pageList1) return 0;
      return pageList1.length;
    },
    pageCount2() {
      const { pageList2 } = this;
      if (!pageList2) return 0;
      return pageList2.length;
    },
    pageList1() {
      const { pageList } = this;
      if (!pageList) return [];
      return pageList.slice(0, this.maxCountPerRow);
    },
    pageList2() {
      const { pageList } = this;
      if (!pageList) return [];
      return pageList.slice(this.maxCountPerRow, pageList.length);
    },
    hostData() {
      let currHostData = null;
      if (this.marketerId !== '') {
        currHostData = this.marketerAgencyContent;
      } else if (this.whiteLabel && !this.whiteLabel.forMainSite) {
        currHostData = this.whiteLabel;
      }
      return currHostData;
    },
    showFacebookLink() {
      return this.marketerId === '' || !!this.hostData?.facebookLink;
    },
    facebookLink() {
      const defaultLink = 'https://he-il.facebook.com/flying.co.il/';
      const link = this.hostData?.facebookLink ? `https://www.facebook.com/${this.hostData?.facebookLink.replace('https://www.facebook.com/', '')}` : defaultLink;
      return link;
    },
    showInstagramLink() {
      return this.marketerId === '' || !!this.hostData?.instagramLink;
    },
    instagramLink() {
      const defaultLink = 'https://www.instagram.com/flying.co.il/';
      const link = this.hostData?.instagramLink ? `https://www.instagram.com/${this.hostData?.instagramLink.replace('https://www.instagram.com/', '')}` : defaultLink;
      return link;
    },
    showTwitterLink() {
      return this.marketerId === '' || !!this.hostData?.twitterLink;
    },
    twitterLink() {
      const defaultLink = 'https://twitter.com/@flyingcarpetltd';
      const link = this.hostData?.twitterLink ? `https://twitter.com/${this.hostData?.twitterLink.replace('https://twitter.com/', '')}` : defaultLink;
      return link;
    },
    showMainFooter() {
      const footerVisibility = this.marketerAgencyContent?.marketerSpecificContents?.footerVisibility ?? 1;
      const show = this.marketerId === '' || (this.marketerId !== '' && footerVisibility === 1);
      return show;
    },
    showCustomSubjectFooter() {
      const footerVisibility = this.marketerAgencyContent?.marketerSpecificContents?.footerVisibility ?? 1;
      const show = this.marketerId !== '' && footerVisibility === 2;
      return show;
    },
    customFooterSubjects() {
      return this.marketerAgencyContent?.marketerSpecificContents?.footerItems ?? [];
    },
    srcLogo() {
      const { marketerAgencyContent } = this;
      return (this.marketerAgencyContent === null || this.marketerAgencyContent === undefined) ? this.whiteLabel?.logoUrls?.[0] : marketerAgencyContent?.logoUrls?.[0];
    },
    showMemberLogin() {
      return this.marketerId === '';
    },
  },
  data() {
    return {
      landingQuery: '',
      firstShowingCount: 8,
      maxCountPerRow: 6,
    };
  },
  mounted() {
    this.landingQuery = window.sessionStorage.getItem('landing-url') ? `${window.location.origin}/${window.sessionStorage.getItem('landing-url')}` : '';
  },
  methods: {
    categoryName(pCode) {
      if (this.lang === 'en') {
        return this.footerCategoryList.find((cat) => (cat.code === pCode))?.nameTranslationEng || '';
      } else {
        return this.footerCategoryList.find((cat) => (cat.code === pCode))?.nameTranslationHeb || '';
      }
    },
  },
};
</script>
